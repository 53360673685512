import React from 'react'
import { connect } from 'react-redux'
import Bredcrumb from '../components/layouts/Bredcrumb'
import Footer from '../components/layouts/Footer'
import Header from '../components/layouts/Header'

 const About = (props) => {
    return (
        <div>
            <Header></Header>
            <Bredcrumb title="About Us" />
            <section class=" about-us">
       <div className="container">
  <div className="row">
    <div className="col-md-6 col-sm-12 col-xs-12">
      <div className="about-page-cntent" style={{padding: '30px 0px'}}>
        <div className="f-title " style={{display: 'block'}}>
          <h2 className=" text-uppercase"> Our Story</h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium nulla eum ducimus, sint dolor aliquam quaerat quam. Ab fugiat rem sapiente eveniet, repellat, magnam veritatis error ipsa, quis dolorum illo.</p>
          <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Magni libero explicabo, maxime, eligendi tenetur sapiente ea aliquam perferendis minus sint cum quas tempore hic, voluptatum ipsum dolore aut tempora dicta!</p>
        </div>
      </div>
    </div>
    <div className="col-md-6 col-sm-12 col-xs-12">
      <div className="story-image"><img alt="banner1" src="./images/about.jpg" /></div>
    </div>
  </div>
</div>

      </section>
      <Footer />
        </div>
    )
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(About)
