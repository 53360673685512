import React from "react";
import { Route, Switch } from "react-router-dom";
import About from "./Pages/About";
import Cart from "./Pages/Cart";
import Checkout from "./Pages/Checkout";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import MyProfile from "./Pages/MyProfile";
import Shop from "./Pages/Shop";
import SingleProduct from "./Pages/SingleProduct";
import BeforeLoginRoutes from "./Routes/BeforeLoginRoutes";
import PrivateRoutes from "./Routes/PrivateRoutes";

function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/about-us" component={About} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/shop" component={Shop} />
      <Route exact path="/product" component={SingleProduct} />
      <Route exact path="/checkout" component={Checkout} />
      <Route exact path="/cart" component={Cart} />
      <Route exact path="/my-profile" component={MyProfile} />
    </Switch>
  );
}

export default Routes;
