import React from 'react'
import { connect } from 'react-redux'
import Bredcrumb from '../components/layouts/Bredcrumb'
import Footer from '../components/layouts/Footer'
import Header from '../components/layouts/Header'

 const Login = (props) => {
    return (
        <div>
            <Header></Header>
            {/* <Bredcrumb title="About Us" /> */}
            <section className style={{backgroundColor: '#f1f1f1', paddingBottom: 50}}>
  <div className="container" data-padding-top={62} style={{paddingTop: 62}}> 
    <div className="row">
      <div className="col-md-6">
        <div className="login-form-content">
          <div className="login-form">
            <form action="#">
              <h4 className="fz-24 mb-25">Log in to your account</h4>
              <div className="form-group row">
                <label className="col-md-3" htmlFor="frm_email">Email</label>
                <div className="col-md-6">
                  <input id="frm_email" className="form-control" type="email" autoComplete="off" />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-3" htmlFor="frm_pass">Password</label>
                <div className="col-md-6">
                  <div className="pass-content">
                    <input type="password" className="form-control" id="frm_pass" />
                  
                  </div>
                </div>
              </div>
              <div className="form-group row mb-15">
                <div className="col-12 text-center">
                  <a className="btn-forgot" href="#/">Forgot your password?</a>
                  <a className="btn-signin" href="#/">Sign in</a>
                </div>
              </div>
            </form>
          </div>
          <div style={{paddingTop:"20px"}} className="text-center">
          <div className="social-sign-in outer-top-xs"><div><a><div className="social-image"><img src="/images/google.png" /></div></a></div>
         <div><a><div className="social-image"><img src="/images/facebook.png" /></div></a></div>
         </div>
          </div>
         

        </div>
        </div>
        <div className="col-md-6">
        <div className="register-form-content">
  <div className="register-form">
  <h4 className="fz-24 mb-25">Create a new Account</h4>
    <form action="#">
    
      <div className="form-group row">
        <label className="col-md-3" htmlFor="f_name"> Name</label>
        <div className="col-md-6">
          <input id="f_name" className="form-control" type="text" />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-md-3" htmlFor="l_name">Phone</label>
        <div className="col-md-6">
          <input id="l_name" className="form-control" type="text" />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-md-3" htmlFor="frm_email">Email</label>
        <div className="col-md-6">
          <input id="frm_email" className="form-control" type="email" />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-md-3" htmlFor="frm_pass">Password</label>
        <div className="col-md-6">
          <div className="pass-content">
            <input type="password" className="form-control" id="frm_pass" />
          
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-md-3" htmlFor="frm_pass"> Confirm Password</label>
        <div className="col-md-6">
          <div className="pass-content">
            <input type="password" className="form-control" id="frm_pass" />
          
          </div>
        </div>
      </div>
      
    </form>
  </div>
  
  <div className="row">
    <div className="col-12 text-center">
      <a className="btn-save" href="#/">Register</a>
    </div>
  </div>
</div>

       
      </div>
    </div>
  </div>
</section>

      <Footer />
        </div>
    )
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
