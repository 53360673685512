import React from 'react'

function Footer() {
    return (
        <div>
             <footer>
  <section className="ptb-50 footer">
    <div className="container">
      <div className="row">
        <div className="col-md-3">
          <div className="footer-heading">
            <h3>ABOUT US</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Possimus deleniti fugiat asperiores deserunt unde, in porro ut
              quibusdam accusamus est vitae ipsa ullam cumque. Voluptatum
              provident exercitationem officiis accusantium tempora!
            </p>
            
          </div>
        </div>
        <div className="col-md-3">
          <div className="footer-heading">
            <h3>CATEGORIES</h3>
            <div className="footer-links">
              <ul>
                <li><a href="#">HOME</a></li>
                <li><a href="#">SHOP</a></li>
                <li><a href="#">COLLECTION</a></li>
                <li><a href="#">PANTS</a></li>
                <li><a href="#">SKIRTS</a></li>
                <li><a href="#">TOP</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="footer-heading">
            <h3>MAIN LINKS</h3>
            <div className="footer-links">
              <ul>
                <li><a href="#">ABOUT US</a></li>
                <li><a href="#">BLOG</a></li>
                <li><a href="#">CONTACT US</a></li>
                <li><a href="#">SHIPPING POLICY</a></li>
                <li><a href="#">RETURN POLICY</a></li>
                <li><a href="#">TERMS &amp; CONDITIONS</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="footer-heading">
            <h3>CONTACT US</h3>
            <div className="address">
              <ul>
                <li>
                  <a href="#"><i className="fa fa-map-marker" /> 123, Main Street,
                    Canada, USA</a>
                </li>
                <li>
                  <a href="#"><i className="fa fa-phone" /> 01234567890</a>
                </li>
                <li>
                  <a href="#"><i className="fa fa-envelope" /> info@example.com</a>
                </li>
              </ul>
            </div>
            <div className="footer-social">
              <ul>
                <li>
                  <a href="#"><i className="fa fa-facebook" /></a>
                </li>
                <li>
                  <a href="#"><i className="fa fa-instagram" /></a>
                </li>
                <li>
                  <a href="#"><i className="fa fa-linkedin" /></a>
                </li>
                <li>
                  <a href="#"><i className="fa fa-youtube" /></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div className="copyright">
    <div className="container">
      <div className="row">
        <div className="copyright-content">
          <p>
            Copyright © 2021, Site Name Design &amp; Development by
            <a href="http://webgrowsolutions.com/">WebGrow Solutions</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>

        </div>
    )
}

export default Footer
