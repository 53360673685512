export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_PROFILE = "GET_PROFILE";
// Products
export const GET_PRODUCTS = "GET_PRODUCTS";
export const TOTAL_PRODUCTS = "TOTAL_PRODUCTS";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const GET_PRODUCT = "GET_PRODUCT";
export const RESET_PRODUCT = "RESET_PRODUCT";
export const PRODUCTS_ERROR = "PRODUCTS_ERROR";
// Category
export const GET_CATEGORYS = "GET_CATEGORYS";
export const TOTAL_CATEGORYS = "TOTAL_CATEGORYS";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const GET_CATEGORY = "GET_CATEGORY";
export const RESET_CATEGORY = "RESET_CATEGORY";
export const CATEGORYS_ERROR = "CATEGORYS_ERROR";
// Order
export const GET_ORDERS = "GET_ORDERS";
export const TOTAL_ORDERS = "TOTAL_ORDERS";
export const ADD_ORDER = "ADD_ORDER";
export const EDIT_ORDER = "EDIT_ORDER";
export const GET_ORDER = "GET_ORDER";
export const RESET_ORDER = "RESET_ORDER";
export const ORDERS_ERROR = "ORDERS_ERROR";
// COUPON
export const GET_COUPONS = "GET_COUPONS";
export const TOTAL_COUPONS = "TOTAL_COUPONS";
export const ADD_COUPON = "ADD_COUPON";
export const EDIT_COUPON = "EDIT_COUPON";
export const GET_COUPON = "GET_COUPON";
export const RESET_COUPON = "RESET_COUPON";
export const COUPONS_ERROR = "COUPONS_ERROR";