import React, { Component, useState } from "react";
import { connect } from "react-redux";
import Footer from "../components/layouts/Footer";
import Header from "../components/layouts/Header";

const Home = () => {
 
  return (
    <div>
      <Header />
    <div className="banner">
  <div className="container">
    <div className="row">
      <div className="banner-content">
        <h5>
          FASHION Exclusively only for <br />
          <span className="you">‘YOU’</span>
        </h5>
        <h2>Women’s Clothing</h2>
        <div className="browse">
          <a href="#" className="btn btn-browse">BROWSE COLLECTION</a>
        </div>
      </div>
    </div>
  </div>
</div>
<section className="ptb-50 best-seller">
  <div className="heading bor">
    <h2>OUR COLLECTIONS</h2>
  </div>
  <div className="container">
    <div className="purchase">
      <div className="row">
        <div className="col-md-3">
          <div className="product-image">
            <img src="./images/sale2.jpg" />
            <div className="product-des">
              <h3>Lahenga</h3>
              
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="product-image">
            <img src="./images/sale4.jpg" />
            <div className="product-des">
              <h3>Saari</h3>
            
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="product-image">
            <img src="./images/sale5.jpg" />
            <div className="product-des">
              <h3>Ethnic</h3>
              
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="product-image">
            <img src="./images/sale1.jpg" />
            <div className="product-des">
              <h3> Kurti</h3>
              
            </div>
          </div>
        </div>
      </div>
    </div>
   
  </div>
</section>
<section className="ptb-50 best-seller"  style={{background:"#f1f1f1"}}>
  <div className="heading bor">
    <h2>NEW ARRIVALS</h2>
  </div>
  <div className="container">
    <div className="purchase">
      <div className="row">
        <div className="col-md-3">
          <div className="product-image">
            <img src="./images/sale2.jpg" />
            <div className="product-des">
              <h3>Teal Blue Kurti</h3>
              <div className="product-rate">
                <div className="rate"><p>₹1699.00</p></div>
                <div className="offer-rate"><del>₹1699.00</del></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="product-image">
            <img src="./images/sale4.jpg" />
            <div className="product-des">
              <h3>Teal Blue Kurti</h3>
              <div className="product-rate">
                <div className="rate"><p>₹1699.00</p></div>
                <div className="offer-rate"><del>₹1699.00</del></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="product-image">
            <img src="./images/sale5.jpg" />
            <div className="product-des">
              <h3>Teal Blue Kurti</h3>
              <div className="product-rate">
                <div className="rate"><p>₹1699.00</p></div>
                <div className="offer-rate"><del>₹1699.00</del></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="product-image">
            <img src="./images/sale1.jpg" />
            <div className="product-des">
              <h3>Teal Blue Kurti</h3>
              <div className="product-rate">
                <div className="rate"><p>₹1699.00</p></div>
                <div className="offer-rate"><del>₹1699.00</del></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="product-image">
            <img src="./images/sale2.jpg" />
            <div className="product-des">
              <h3>Teal Blue Kurti</h3>
              <div className="product-rate">
                <div className="rate"><p>₹1699.00</p></div>
                <div className="offer-rate"><del>₹1699.00</del></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="product-image">
            <img src="./images/sale4.jpg" />
            <div className="product-des">
              <h3>Teal Blue Kurti</h3>
              <div className="product-rate">
                <div className="rate"><p>₹1699.00</p></div>
                <div className="offer-rate"><del>₹1699.00</del></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="product-image">
            <img src="./images/sale5.jpg" />
            <div className="product-des">
              <h3>Teal Blue Kurti</h3>
              <div className="product-rate">
                <div className="rate"><p>₹1699.00</p></div>
                <div className="offer-rate"><del>₹1699.00</del></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="product-image">
            <img src="./images/sale1.jpg" />
            <div className="product-des">
              <h3>Teal Blue Kurti</h3>
              <div className="product-rate">
                <div className="rate"><p>₹1699.00</p></div>
                <div className="offer-rate"><del>₹1699.00</del></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="text-center view-more">
      <a href="#" className="btn btn-view-more">VIEW MORE</a>
    </div>
  </div>
</section>
<section className="ptb-50 unique">
  <div className="container">
    <div className="row">
      <div className="col-md-4">
        <div className="unique-box">
          <div className="aligncenter">
            <div className="icon-wrapper">
              <span className="fa fa-truck"> </span>
            </div>
            <div className="icon-content">
              <h4>Unique Designs</h4>
             
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="unique-box">
          <div className="aligncenter">
            <div className="icon-wrapper">
              <span className="fa fa-globe"> </span>
            </div>
            <div className="icon-content">
              <h4>Free Returns</h4>
             
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="unique-box">
          <div className="aligncenter">
            <div className="icon-wrapper">
              <span className="fa fa-plus"> </span>
            </div>
            <div className="icon-content">
              <h4>SPECIAL GIFT CARDS
</h4>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="gallery">

</section>
<section className="ptb-50 post-newsletter">
  <div className="heading">
    <h2>Newsletter</h2>
    <p className="news">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis
      justo, potenti risus semper turpis lacus urna. Dolor fermentum
    </p>
  </div>
  <div className="container">
    <div className="text-center">
      <div className="row">
        <div className="news-form">
          <div className="form-group">
            <label htmlFor="newsletter-input" /><input className="newsletter-input" name="email" type="text" placeholder="Enter Email" id="newsletter-input"  /><button className="btn" name="submitNewsletter" type="submit">
              <span> Subscribe </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<Footer />

    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
