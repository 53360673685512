import React from "react";
import { connect } from "react-redux";
import Bredcrumb from "../components/layouts/Bredcrumb";
import Footer from "../components/layouts/Footer";
import Header from "../components/layouts/Header";

const MyProfile = (props) => {
  return (
    <div>
      <Header></Header>
      <Bredcrumb title="My Profile" />
      <section class=" about-us">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div
                className="sidebar-widget"
                style={{
                  background: "none 0px 0px repeat scroll rgb(251, 251, 251)",
                  padding: 10,
                }}
              >
                <div className="sidebar-flex">
                  <div className="dash">
                    <a href="#">Profile</a>
                  </div>
                </div>
                <div className="sidebar-flex">
                  <div className="dash">
                    <a href="#">My Orders</a>
                  </div>
                </div>
                <div className="sidebar-flex">
                  <div className="dash">
                    <a href="#">My Address</a>
                  </div>
                </div>
                <div className="sidebar-flex">
                  <div className="dash">
                    <a href="#">Reset Password</a>
                  </div>
                </div>
                <div className="sidebar-flex">
                  <div className="dash">
                    <a>Logout</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-8 col-sm-12 col-xs-12">
              <div
                className="about-page-cntent"
                style={{
                  padding: "30px 0px",
                  border: "1px solid #f1f1f1",
                  padding: "10px 20px",
                  margin: "20px 0px",
                }}
              >
                <div className=" create-new-account">
                  <h3 className="page-subheading">Edit Profile</h3>
                  <form action="#">
                    <div className="row">
                      <div className="col-md-12">
                        <label htmlFor="name">Name</label>
                        <input
                          className="form-control"
                          name="name"
                          type="text"
                          placeholder="Enter Name"
                        />
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="email">Email</label>
                        <input
                          className="form-control"
                          name="email"
                          type="text"
                          disabled
                          placeholder="Enter Email"
                          defaultValue="nishant.tripathi7700@gmail.com"
                        />
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="phone">Phone</label>
                        <input
                          className="form-control"
                          name="phone"
                          type="text"
                          placeholder="Enter Phone"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="dob">Date of Birth</label>
                        <input
                          className="form-control"
                          name="dob"
                          type="date"
                          placeholder="Enter Date of Birth"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="gender">Gender</label>
                        <select
                          className="form-control custom-select"
                          name="gender"
                          placeholder="Enter Gender"
                        >
                          <option value> Select Gender </option>
                          <option value="Male"> Male </option>
                          <option value="Female"> Female </option>
                          <option value="others"> Others </option>
                        </select>
                      </div>
                      <div className="col-md-12">
                        <button
                          className="btn btn-success "
                          style={{ margin: "20px 0px" }}
                        >
                          {" "}
                          Save{" "}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
