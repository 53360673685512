import { combineReducers } from "redux";
import auth from "./auth";
import alert from "./alert";
import product from "./product";
import category from "./category";
import order from "./order"
import coupon from "./coupon";
export default combineReducers({
  auth,
  alert,
  product,
  category,
  order,
  coupon
});
