import React from "react";
import { connect } from "react-redux";
import Bredcrumb from "../components/layouts/Bredcrumb";
import Footer from "../components/layouts/Footer";
import Header from "../components/layouts/Header";

const Cart = (props) => {
  return (
    <div>
      <Header></Header>
      <Bredcrumb title="Cart" />
      <div className="cart-main-area" style={{ padding: "30px 0px" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="page-heading">Shopping-cart summary</h1>
            </div>
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="only-desktop">
                <div className="table-content table-responsive">
                  <table>
                    <thead>
                      <tr>
                        <th className="product-thumbnail">Image</th>
                        <th className="product-name">Product</th>
                        <th className="product-price">Price</th>
                        <th className="product-quantity">Quantity</th>
                        <th className="product-subtotal">Total</th>
                        <th className="product-remove">Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="product-thumbnail">
                          <a href="#">
                            <img
                              src="/images/sale2.jpg"
                              alt="product1"
                              style={{
                                width: 175,
                                height: 120,
                                objectFit: "contain",
                              }}
                            />
                          </a>
                        </td>
                        <td className="product-name">
                          All over floral pattern pattern dress featuring a
                          ruffled tie collar dress - S{" "}
                        </td>
                        <td className="product-price">
                          <span className="amount"> Rs 1599.00 </span>
                        </td>
                        <td className="product-quantity">
                          <div className="quantity">
                            <button
                              type="button"
                              style={{
                                background: "rgb(207, 92, 124)",
                                border: "1px solid rgb(207, 92, 124)",
                                color: "rgb(255, 255, 255)",
                                padding: 10,
                                fontSize: 18,
                                borderRadius: 5,
                              }}
                            >
                              -
                            </button>
                            <input
                              type="text"
                              disabled
                              name="quantity"
                              title="Qty"
                              className="qty"
                              size={4}
                              defaultValue={1}
                              style={{ height: "45px", textAlign: "center" }}
                            />
                            <button
                              type="button"
                              style={{
                                background: "rgb(207, 92, 124)",
                                border: "1px solid rgb(207, 92, 124)",
                                color: "rgb(255, 255, 255)",
                                padding: 10,
                                fontSize: 18,
                                borderRadius: 5,
                              }}
                            >
                              +
                            </button>
                          </div>
                        </td>
                        <td className="product-subtotal">Rs 1599.00</td>
                        <td className="product-remove">
                          <a>
                            <i className="fa fa-times" />
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="only-mobile">
                <div
                  style={{
                    display: "flex",
                    background: "rgb(255, 255, 255)",
                    marginBottom: 20,
                    border: "1px solid rgb(241, 241, 241)",
                    borderRadius: 10,
                    padding: 10,
                  }}
                >
                  <div style={{ borderRadius: 10, flex: "1 1 0%" }}>
                    <img
                      src="https://api.anysa.in/uploads/24321_6_111_fcd7a05439.jpg"
                      alt="product1"
                      style={{ width: 175, height: 120, objectFit: "contain" }}
                    />
                  </div>
                  <div style={{ flex: "2 1 0%", padding: 10 }}>
                    <div style={{ fontSize: 12 }}>
                      All over floral pattern pattern dress featuring a ruffled
                      tie collar dress - S{" "}
                    </div>
                    <div style={{ alignSelf: "flex-end" }}>
                      <span className="amount"> Rs 1599.00 </span>
                    </div>
                  </div>
                  <div style={{ flex: "1 1 0%" }}>
                    <div style={{ textAlign: "right" }}>
                      <a style={{ color: "rgb(51, 51, 51)" }}>
                        <i className="fa fa-times" />
                      </a>
                    </div>
                    <div
                      className="quantity"
                      style={{ display: "flex", paddingTop: 20 }}
                    >
                      <a
                        type="button"
                        style={{ fontSize: 28, color: "rgb(51, 51, 51)" }}
                      >
                        -
                      </a>
                      <div
                        style={{
                          padding: 10,
                          fontWeight: "bold",
                          fontSize: 20,
                        }}
                      >
                        1
                      </div>
                      <a
                        type="button"
                        style={{ fontSize: 28, color: "rgb(51, 51, 51)" }}
                      >
                        +
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-8 col-sm-7 col-xs-12">
                  <div
                    class="browse"
                    style={{ textAlign: "left", paddingTop: "30px" }}
                  >
                    <a href="#" class="btn btn-browse">
                      BROWSE COLLECTION
                    </a>
                  </div>
                </div>
                <div className="col-md-4 col-sm-5 col-xs-12">
                  <div className="cart_totals">
                    <table>
                      <tbody>
                        <tr className="cart-subtotal">
                          <th>Subtotal</th>
                          <td>
                            <span className="amount">Rs 1599</span>
                          </td>
                        </tr>
                        <tr className="order-total">
                          <th>Total</th>
                          <td>
                            <strong>
                              <span className="amount">Rs 1599</span>
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="wc-proceed-to-checkout">
                      <div class=" view-more">
                        <a
                          href="#"
                          class="btn btn-view-more"
                          style={{ background: "#000" }}
                        >
                          Proceed to Checkout
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
