import React, { useState } from 'react'

export default function Header() {
  const [openMenu, setOpenMenu] = useState(false)
    return (
       <header>
  <section className="top-header">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="header-left">
            <ul>
              <li>
                <a href="#"><i className="fa fa-phone" /> 0123456789</a>
              </li>
              <li>
                <a href="#"><i className="fa fa-envelope" /> info@example.com</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="main-header only-desktop">
    <div className="container">
      <div className="row">
        {/* navar */}
        {/* mobile header */}
        <div className="only-mobile" style={{display: 'none'}}>
          <div className="nav-toggle">
            <div className="posi">
              <a href="#"><i className="fa fa-bars" /></a>
            </div>
            <div className="mobile-menu">
              <div className="mobile-menu-open">
                <div className="menu-box">
                  <div className="mobile-nav">
                    <div className="mobile-main-nav">
                      <div className style={{textAlign: 'right'}}>
                        <i className="fa fa-close" />
                      </div>
                      <ul>
                        <li><a href="#">HOME</a></li>
                        <li><a href="#">SHOP</a></li>
                        <li><a href="#">COLLECTION</a></li>
                        <li><a href="#">PANTS</a></li>
                        <li><a href="#">SKIRTS</a></li>
                        <li><a href="#">TOPS</a></li>
                        <li><a href="#">BLOG</a></li>
                        <li><a href="#">ABOUT US</a></li>
                        <li><a href="#">CONTACT US</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* mobile header */}
        <div className="row">
          <div className="col-md-4">
            <div className="header-left">
              <form method="get" id="searchform" >
                <input type="text" 
                 name="s" id="s" className="text_input" /><input name="submit" type="submit" defaultValue="Go" />
              </form>
            </div>
          </div>
          <div className="col-md-4">
            <div className="logo">
              <a href>LOGO HERE </a>
            </div>
          </div>
          <div className="col-md-4">
            <div className="shoping-card">
              <ul>
                <li>
                  <a href><i className="fa fa-user" /></a>
                </li>
                <li>
                  <a href><i className="fa fa-heart" /></a>
                </li>
                <li>
                  <a href><i className="fa fa-shopping-bag" />
                    <p className="cart-icon"><span>0</span></p></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <hr className="only-desktop" />
  <div className="navbar-auto">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="navbar-area">
            <div className="text-center">
              <ul>
                <li><a href="#">HOME</a></li>
                <li><a href="#">SHOP</a></li>
                <li><a href="#">COLLECTION</a></li>
                <li><a href="#">PANTS</a></li>
                <li><a href="#">SKIRTS</a></li>
                <li><a href="#">TOPS</a></li>
                <li><a href="#">BLOG</a></li>
                <li><a href="#">ABOUT US</a></li>
                <li><a href="#">CONTACT US</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="only-mobile mobile-menu">
     <div className="d-flex justify-content-between">
            <div className="mogile-logo">
                Logo 
            </div>
            <div className="mobile-menu-links">
                    <a href=""> <i className="fa fa-search" ></i> </a>
                    <a href=""> <i className="fa fa-user" ></i> </a>
                    <a style={{background:"#000",color:"#fff",borderRadius:"5px"}} onClick={()=>setOpenMenu(!openMenu)} >  <i className={ openMenu ? "fa fa-close" : "fa fa-bars"} ></i> Menu </a>
                
            </div>
         </div>  
         <div className="mobile-menu-opened" style={{display:openMenu ? "block":"none"}} >
         <div className="mobile-welcome" >
           <div className="overlay"></div>
           <div className="welcome-content">
             <div className="welcome-message">
              <h3> Hi Nishant </h3>
             </div>
             <div>
                    <ul>
                        <li>
                            <a href=""> My Profile </a>
                           
                        </li>
                        <li>
                        <a href=""> My Orders </a>
                           
                           
                        </li>
                        <li>
                        <a href=""> My Saved Address </a>  
                        </li>
                        <li>
                        <a href=""> Logout </a>  
                        </li>
                    </ul>
                    </div>
             </div>
                             </div>
         <div className="mobile-menu-links">
            
              <ul className="main-menu">
                <li><a href="#">HOME</a></li>
                <li><a href="#">SHOP</a></li>
                <li><a href="#">COLLECTION</a></li>
                <li><a href="#">PANTS</a></li>
                <li><a href="#">SKIRTS</a></li>
                <li><a href="#">TOPS</a></li>
                <li><a href="#">BLOG</a></li>
                <li><a href="#">ABOUT US</a></li>
                <li><a href="#">CONTACT US</a></li>
              </ul>
            </div>
             </div>     

        <div className="footer-cart" >
        <div className="cart-bg">
                  <a href><i className="fa fa-shopping-bag" />
                   </a>
                </div>
        </div>
  </div>
</header>

    )
}
