import React, { useState } from 'react'
import { connect } from 'react-redux'
import Bredcrumb from '../components/layouts/Bredcrumb'
import Footer from '../components/layouts/Footer'
import Header from '../components/layouts/Header'

 const Shop = (props) => {
     const [activeFilter, setActiveFilter] = useState(false)
    return (
        <div>
            <Header></Header>
            <Bredcrumb title="Shop" />
           <section className="shop">
  <div className="container">
    <div className="row">
      <div className="col-md-3">
          <div className="only-mobile">
              <button onClick={()=>setActiveFilter(!activeFilter)} className="btn btn-secondary"> <i className={ !activeFilter ? "fa fa-filter" : "fa fa-close"}></i> Filters </button>
          </div>
        <div className="sidebar" style={{display:window.innerWidth <991 && !activeFilter  ? "none" :"block" }}>
          <div className="layered-sidebar">
            <div className="sub-categories">
              <div className="sub-content-price">
                <div className="layered-subtitle-heading"><span className="layered-subtitle">Price</span></div>
                <ul className="layered-category col-lg-12">
                  <li className="col-lg-12">
                    <div className="checker"><span><input type="checkbox" className="checkbox" id="price-0-999" /></span></div>
                    <label htmlFor="price-0-999">Rs 0-999</label>
                  </li>
                  <li className="col-lg-12">
                    <div className="checker"><span><input type="checkbox" className="checkbox" id="price-1000-1999" /></span></div>
                    <label htmlFor="price-1000-1999">Rs 1000-1999</label>
                  </li>
                  <li className="col-lg-12">
                    <div className="checker"><span><input type="checkbox" id="price-2000-2999" className="checkbox" /></span></div>
                    <label htmlFor="price-2000-2999">Rs 2000-2000</label>
                  </li>
                  <li className="col-lg-12">
                    <div className="checker"><span><input type="checkbox" id="price-3000plus" className="checkbox" /></span></div>
                    <label htmlFor="price-3000plus">Rs 3000+</label>
                  </li>
                </ul>
              </div>
              <div className="sub-content">
                <div className="layered-subtitle-heading"><span className="layered-subtitle">Availability</span></div>
                <ul className="layered-category col-lg-12">
                  <li className="col-lg-12">
                    <div className="checker"><span><input type="checkbox" id="in_stock" className="checkbox" defaultChecked /></span></div>
                    <label htmlFor="in_stock">In stock</label>
                  </li>
                </ul>
              </div>
              <div className="sub-content">
                <div className="layered-subtitle-heading"><span className="layered-subtitle">Size</span></div>
                <ul className="layered-category col-lg-12">
                  <li className="col-lg-12">
                    <div className="checker"><span><input type="checkbox" id="size-xs" className="checkbox" defaultChecked /></span></div>
                    <label htmlFor="size-xs">XS</label>
                  </li>
                  <li className="col-lg-12">
                    <div className="checker"><span><input id="size-s" type="checkbox" className="checkbox" defaultChecked /></span></div>
                    <label htmlFor="size-s">S</label>
                  </li>
                  <li className="col-lg-12">
                    <div className="checker"><span><input id="size-m" type="checkbox" className="checkbox" defaultChecked /></span></div>
                    <label htmlFor="size-m">M</label>
                  </li>
                  <li className="col-lg-12">
                    <div className="checker"><span><input id="size-l" type="checkbox" className="checkbox" defaultChecked /></span></div>
                    <label htmlFor="size-l">L</label>
                  </li>
                  <li className="col-lg-12">
                    <div className="checker"><span><input id="size-xl" type="checkbox" className="checkbox" defaultChecked /></span></div>
                    <label htmlFor="size-xl">XL</label>
                  </li>
                </ul>
              </div>
              <div className="sub-content">
                <div className="layered-subtitle-heading"><span className="layered-subtitle">Sort By</span></div>
                <ul className="layered-category col-lg-12">
                  <li className="col-lg-12">
                    <div className="checker"><span><input type="checkbox" id="lth" className="checkbox" /></span></div>
                    <label htmlFor="lth">Price low to high</label>
                  </li>
                  <li className="col-lg-12">
                    <div className="checker"><span><input type="checkbox" id="htl" className="checkbox" /></span></div>
                    <label htmlFor="htl">Price high to low</label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-9">
        <div className="shop-item">
          <div className="row">
            <div className="col-md-3">
              <div className="product-image">
                <img src="./images/sale2.jpg" />
                <div className="product-des">
                  <h3>Teal Blue Kurti</h3>
                  <div className="product-rate">
                    <div className="rate">
                      <p>₹1699.00</p>
                    </div>
                    <div className="offer-rate"><del>₹1699.00</del></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="product-image">
                <img src="./images/sale2.jpg" />
                <div className="product-des">
                  <h3>Teal Blue Kurti</h3>
                  <div className="product-rate">
                    <div className="rate">
                      <p>₹1699.00</p>
                    </div>
                    <div className="offer-rate"><del>₹1699.00</del></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="product-image">
                <img src="./images/sale2.jpg" />
                <div className="product-des">
                  <h3>Teal Blue Kurti</h3>
                  <div className="product-rate">
                    <div className="rate">
                      <p>₹1699.00</p>
                    </div>
                    <div className="offer-rate"><del>₹1699.00</del></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="product-image">
                <img src="./images/sale2.jpg" />
                <div className="product-des">
                  <h3>Teal Blue Kurti</h3>
                  <div className="product-rate">
                    <div className="rate">
                      <p>₹1699.00</p>
                    </div>
                    <div className="offer-rate"><del>₹1699.00</del></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="product-image">
                <img src="./images/sale2.jpg" />
                <div className="product-des">
                  <h3>Teal Blue Kurti</h3>
                  <div className="product-rate">
                    <div className="rate">
                      <p>₹1699.00</p>
                    </div>
                    <div className="offer-rate"><del>₹1699.00</del></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="product-image">
                <img src="./images/sale2.jpg" />
                <div className="product-des">
                  <h3>Teal Blue Kurti</h3>
                  <div className="product-rate">
                    <div className="rate">
                      <p>₹1699.00</p>
                    </div>
                    <div className="offer-rate"><del>₹1699.00</del></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="product-image">
                <img src="./images/sale2.jpg" />
                <div className="product-des">
                  <h3>Teal Blue Kurti</h3>
                  <div className="product-rate">
                    <div className="rate">
                      <p>₹1699.00</p>
                    </div>
                    <div className="offer-rate"><del>₹1699.00</del></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="product-image">
                <img src="./images/sale2.jpg" />
                <div className="product-des">
                  <h3>Teal Blue Kurti</h3>
                  <div className="product-rate">
                    <div className="rate">
                      <p>₹1699.00</p>
                    </div>
                    <div className="offer-rate"><del>₹1699.00</del></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

      <Footer />
        </div>
    )
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(Shop)
