import React from 'react'
import { connect } from 'react-redux'
import Bredcrumb from '../components/layouts/Bredcrumb'
import Footer from '../components/layouts/Footer'
import Header from '../components/layouts/Header'
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import InnerImageZoom from "react-inner-image-zoom";
 const SingleProduct = (props) => {
    
    return (
        <div>
            <Header></Header>
            <Bredcrumb title="Single Product" />
            <div className=" product-details-area">
  <div className="container">
    <div className="row product-details">
      <div className="product-left-column col-lg-4 col-md-4 col-sm-5">
        <div className="product-s-l">
          <div className="product-large">
            <div className="tab-content">
              <div className="tab-pane active in" id="img-1">
                <figure className="iiz  ">
                  <div><img className="iiz__img  " src="./images/sale2.jpg" style={{transition: 'opacity 0ms linear 0ms, visibility 150ms linear 0ms'}} /></div>
                  <span className="iiz__btn iiz__hint"><i className="fa fa-search-plus" /></span>
                </figure>
              </div>
            </div>
          </div>
          <div className="product-small">
            <ul className="nav nav-tabs bxslider" role="tablist">
              <li className="active"><a className=" active"><img src="./images/sale2.jpg" alt="product_small_img1" style={{width: 75, height: 50, objectFit: 'contain', display: 'inline-block'}} /></a></li>
              <li className="active"><a className=" active"><img src="./images/sale4.jpg" alt="product_small_img1" style={{width: 75, height: 50, objectFit: 'contain', display: 'inline-block'}} /></a></li>
              <li className="active"><a className=" active"><img src="./images/sale2.jpg" alt="product_small_img1" style={{width: 75, height: 50, objectFit: 'contain', display: 'inline-block'}} /></a></li>
              <li className="active"><a className=" active"><img src="./images/sale1.jpg" alt="product_small_img1" style={{width: 75, height: 50, objectFit: 'contain', display: 'inline-block'}} /></a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="product-center-column col-lg-7 col-md-7 col-sm-7">
        <h1> Conscious front tie, gathered shoulders, belted shirt dress </h1>
        <p><label>Category: </label><span> Women  &gt; Shirts &gt; Shirt &gt; Belt</span></p>
        <p><label>Color: </label><span className="editable"> White </span></p>
        <p><label>Material: </label><span className="editable"> 100% Cotton </span></p>
        <p> <label> Days to Dispatch: </label> 4-5 days </p>
        <div className="content-prices clearfix">
          <p className="price-display"><span className="price" id="price-display">Rs 999</span> </p>
          <div className="clearfix" />
        </div>
        <hr />
        <div className="product-quantity-available">
          <p id="availability"><span>In stock</span></p>
        </div>
        <h4> Size </h4>
        <div style={{display: 'flex', margin: '10px 0px'}}>
          <div style={{border: '1px dotted rgb(140, 140, 140)', padding: 10, cursor: 'pointer', marginRight: 10, background: 'none', color: 'rgb(0, 0, 0)'}}>XS</div>
          <div style={{border: '1px dotted rgb(140, 140, 140)', padding: 10, cursor: 'pointer', marginRight: 10, background: 'none', color: 'rgb(0, 0, 0)'}}>S</div>
          <div style={{border: '1px dotted rgb(140, 140, 140)', padding: 10, cursor: 'pointer', marginRight: 10, background: 'none', color: 'rgb(0, 0, 0)'}}>M</div>
          <div style={{border: '1px dotted rgb(140, 140, 140)', padding: 10, cursor: 'pointer', marginRight: 10, background: 'none', color: 'rgb(0, 0, 0)'}}>L</div>
          <div style={{border: '1px dotted rgb(140, 140, 140)', padding: 10, cursor: 'pointer', marginRight: 10, background: 'none', color: 'rgb(0, 0, 0)'}}>XL</div>
        </div>
        <div style={{padding: '10px 0px'}}>
          <div>
            <div className="cart_extra">
              <div className="product-details-buttons">
                <div className=" view-more">
                  <a href="#" className="btn btn-view-more">Add To Cart</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h4 style={{marginTop: 20}}> Description </h4>
        <p>Crisp cotton, gathered shoulders, wide cuffs shirt, can be nipped at the waist using a tie belt</p>
        <hr />
        <div style={{paddingBottom: 20}}>
          <h4 style={{paddingBottom: 20}}> Wash care Instructions- </h4>
          <ul className="machine">
            <li style={{listStyleType: 'disc'}}>Machine wash at max. 30ºC/86ºF with short spin cycle</li>
            <li style={{listStyleType: 'disc'}}> Wash dark colors separately</li>
            <li style={{listStyleType: 'disc'}}> Do not use bleach</li>
            <li style={{listStyleType: 'disc'}}> Iron at a maximum of 110ºC/230ºF</li>
            <li style={{listStyleType: 'disc'}}> Do not dry under direct sunlight</li>
            <li style={{listStyleType: 'disc'}}> Do not tumble dry</li>
            <li style={{listStyleType: 'disc'}}>Do not use iron directly on prints/embroidery</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<section style={{backgroundColor: '#f1f1f1'}} className=" ptb-50 best-seller">
  <div className="heading">
    <h2>RELATED PRODUCTS</h2>
  </div>
  <div className="container">
    <div className="purchase">
      <div className="row single">
        <div className="col-md-3">
          <div className="product-image">
            <img src="./images/sale2.jpg" />
            <div className="product-des">
              <h3>Teal Blue Kurti</h3>
              <div className="product-rate">
                <div className="rate"><p>₹1699.00</p></div>
                <div className="offer-rate"><del>₹1699.00</del></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="product-image">
            <img src="./images/sale4.jpg" />
            <div className="product-des">
              <h3>Teal Blue Kurti</h3>
              <div className="product-rate">
                <div className="rate"><p>₹1699.00</p></div>
                <div className="offer-rate"><del>₹1699.00</del></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="product-image">
            <img src="./images/sale5.jpg" />
            <div className="product-des">
              <h3>Teal Blue Kurti</h3>
              <div className="product-rate">
                <div className="rate"><p>₹1699.00</p></div>
                <div className="offer-rate"><del>₹1699.00</del></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="product-image">
            <img src="./images/sale1.jpg" />
            <div className="product-des">
              <h3>Teal Blue Kurti</h3>
              <div className="product-rate">
                <div className="rate"><p>₹1699.00</p></div>
                <div className="offer-rate"><del>₹1699.00</del></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="text-center view-more mt-5">
      <a href="#" className="btn btn-view-more">VIEW MORE</a>
    </div>
  </div>
</section>

      <Footer />
        </div>
    )
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleProduct)
