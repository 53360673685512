import React from 'react'
import { connect } from 'react-redux'

 const Bredcrumb = ({title}) => {
    return (
        <div>
           <div>
  <div className="breadcrumb-area">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="breadcrumb">
            <a title="Return to Home" className="home" href="/"><i className="fa fa-home" />Home</a><span className="navigation">&gt;</span> 
            <p>{title}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

        </div>
    )
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(Bredcrumb)
