import React from "react";
import { connect } from "react-redux";
import Bredcrumb from "../components/layouts/Bredcrumb";
import Footer from "../components/layouts/Footer";
import Header from "../components/layouts/Header";

const Checkout = (props) => {
  return (
    <div>
      <Header></Header>
      <Bredcrumb title="Checkout" />
      <section style={{ backgroundColor: "#fff", paddingBottom: 50 }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="login-form-content">
                <div className="login-form">
                  <form action="#">
                    <h4 className="fz-24 mb-25">Log in to your account</h4>
                    <div className="form-group row">
                      <label className="col-md-3" htmlFor="frm_email">
                        Email
                      </label>
                      <div className="col-md-6">
                        <input
                          id="frm_email"
                          className="form-control"
                          type="email"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-3" htmlFor="frm_pass">
                        Password
                      </label>
                      <div className="col-md-6">
                        <div className="pass-content">
                          <input
                            type="password"
                            className="form-control"
                            id="frm_pass"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group row mb-15">
                      <div className="col-12 text-center">
                        <a className="btn-forgot" href="#/">
                          Forgot your password?
                        </a>
                        <a className="btn-signin" href="#/">
                          Sign in
                        </a>
                      </div>
                    </div>
                  </form>
                </div>
                <div style={{ paddingTop: "20px" }} className="text-center">
                  <div className="social-sign-in outer-top-xs">
                    <div>
                      <a>
                        <div className="social-image">
                          <img src="/images/google.png" />
                        </div>
                      </a>
                    </div>
                    <div>
                      <a>
                        <div className="social-image">
                          <img src="/images/facebook.png" />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="register-form-content">
                <div className="register-form">
                  <h4 className="fz-24 mb-25">Create a new Account</h4>
                  <form action="#">
                    <div className="form-group row">
                      <label className="col-md-3" htmlFor="f_name">
                        {" "}
                        Name
                      </label>
                      <div className="col-md-6">
                        <input
                          id="f_name"
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-3" htmlFor="l_name">
                        Phone
                      </label>
                      <div className="col-md-6">
                        <input
                          id="l_name"
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-3" htmlFor="frm_email">
                        Email
                      </label>
                      <div className="col-md-6">
                        <input
                          id="frm_email"
                          className="form-control"
                          type="email"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-3" htmlFor="frm_pass">
                        Password
                      </label>
                      <div className="col-md-6">
                        <div className="pass-content">
                          <input
                            type="password"
                            className="form-control"
                            id="frm_pass"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-3" htmlFor="frm_pass">
                        {" "}
                        Confirm Password
                      </label>
                      <div className="col-md-6">
                        <div className="pass-content">
                          <input
                            type="password"
                            className="form-control"
                            id="frm_pass"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="row">
                  <div className="col-12 text-center">
                    <a className="btn-save" href="#/">
                      Register
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className=" register-form-content">
                  <div className="register-form">
                    <h3 className="page-subheading">2. Billing Details</h3>
                    <div className="row" />
                    <hr />
                    <div>
                      <div className>
                        <form action="#">
                          <div className="row">
                            <div className="col-md-12 form-group">
                              <label htmlFor="name">Name</label>
                              <input
                                className="form-control"
                                name="name"
                                type="text"
                                placeholder="Enter Name"
                              />
                            </div>
                            <div className="col-md-12 form-group">
                              <label htmlFor="phone">Phone</label>
                              <input
                                className="form-control"
                                name="phone"
                                type="text"
                                placeholder="Enter Phone"
                              />
                            </div>
                            <div className="col-md-12 form-group">
                              <label htmlFor="email">Email</label>
                              <input
                                className="form-control"
                                name="email"
                                type="text"
                                disabled
                                placeholder="Enter Email"
                              />
                            </div>
                            <div className="col-md-12 form-group">
                              <label htmlFor="address_1">Address 1</label>
                              <input
                                className="form-control"
                                name="address_1"
                                type="text"
                                placeholder="Enter Address 1"
                              />
                            </div>
                            <div className="col-md-12 form-group">
                              <label htmlFor="address_2">Address Line 2</label>
                              <input
                                className="form-control"
                                name="address_2"
                                type="text"
                                placeholder="Enter Address Line 2"
                              />
                            </div>
                            <div className="col-md-12 form-group">
                              <label htmlFor="landmark">Landmark</label>
                              <input
                                className="form-control"
                                name="landmark"
                                type="text"
                                placeholder="Enter Landmark"
                              />
                            </div>
                            <div className="col-md-12 form-group">
                              <label htmlFor="city">City</label>
                              <input
                                className="form-control"
                                name="city"
                                type="text"
                                placeholder="Enter City"
                              />
                            </div>
                            <div className="col-md-12 form-group">
                              <label htmlFor="state">State</label>
                              <select
                                className="form-control custom-select"
                                name="state"
                                placeholder="Enter State"
                              >
                                <option value>Select State</option>
                                <option value="Andhra Pradesh">
                                  Andhra Pradesh
                                </option>
                                <option value="Arunachal Pradesh">
                                  Arunachal Pradesh
                                </option>
                                <option value="Assam">Assam</option>
                                <option value="Bihar">Bihar</option>
                                <option value="Chhattisgarh">
                                  Chhattisgarh
                                </option>
                                <option value="Goa">Goa</option>
                                <option value="Gujarat">Gujarat</option>
                                <option value="Haryana">Haryana</option>
                                <option value="Himachal Pradesh">
                                  Himachal Pradesh
                                </option>
                                <option value="Jammu and Kashmir">
                                  Jammu and Kashmir
                                </option>
                                <option value="Jharkhand">Jharkhand</option>
                                <option value="Karnataka">Karnataka</option>
                                <option value="Kerala">Kerala</option>
                                <option value="Ladakh">Ladakh</option>
                                <option value="Madhya Pradesh">
                                  Madhya Pradesh
                                </option>
                                <option value="Maharashtra">Maharashtra</option>
                                <option value="Manipur">Manipur</option>
                                <option value="Meghalaya">Meghalaya</option>
                                <option value="Mizoram">Mizoram</option>
                                <option value="Nagaland">Nagaland</option>
                                <option value="Odisha">Odisha</option>
                                <option value="Punjab">Punjab</option>
                                <option value="Rajasthan">Rajasthan</option>
                                <option value="Sikkim">Sikkim</option>
                                <option value="Tamil Nadu">Tamil Nadu</option>
                                <option value="Telangana">Telangana</option>
                                <option value="Tripura">Tripura</option>
                                <option value="Uttarakhand">Uttarakhand</option>
                                <option value="Uttar Pradesh">
                                  Uttar Pradesh
                                </option>
                                <option value="West Bengal">West Bengal</option>
                                <option value="Andaman and Nicobar Islands">
                                  Andaman and Nicobar Islands
                                </option>
                                <option value="Chandigarh">Chandigarh</option>
                                <option value="Dadra and Nagar Haveli">
                                  Dadra and Nagar Haveli
                                </option>
                                <option value=" Daman and Diu">
                                  Daman and Diu
                                </option>
                                <option value="Delhi">Delhi</option>
                                <option value="Lakshadeep">Lakshadeep</option>
                                <option value="Pondicherry">
                                  Pondicherry (Puducherry)
                                </option>
                              </select>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label htmlFor="pin">Pin</label>
                                <input
                                  className="form-control"
                                  name="pin"
                                  type="text"
                                  placeholder="Enter Pin"
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <button type="submit" className="btn-save">
                                  <span> Submit</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="your-order"
                  style={{ border: "1px solid #f1f1f1", padding: "10px 10px" }}
                >
                  <h3>Your order</h3>
                  <div>
                    <div className="your-order-table table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="product-thumbnail">&nbsp;</th>
                            <th className="product-name">Product</th>
                            <th className="product-price">Price</th>
                            <th className="product-quantity">Quantity</th>
                            <th className="product-subtotal">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="product-thumbnail">
                              <img
                                src="/images/sale2.jpg"
                                alt="product1"
                                style={{
                                  width: 150,
                                  height: 100,
                                  objectFit: "contain",
                                }}
                              />
                            </td>
                            <td className="product-name" data-title="Product">
                              {" "}
                              All over floral pattern pattern dress featuring a
                              ruffled tie collar dress-S
                            </td>
                            <td className="product-price" data-title="Price">
                              Rs 1599.00
                            </td>
                            <td
                              className="product-quantity"
                              data-title="Quantity"
                            >
                              <div className="quantity">1</div>
                            </td>
                            <td className="product-subtotal" data-title="Total">
                              Rs 1599.00
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="table-responsive cart"
                    style={{ background: "rgb(255, 255, 255)" }}
                  >
                    <table className="table">
                      <tbody>
                        <tr>
                          <td className="cart_total_label">Cart Subtotal</td>
                          <td className="cart_total_amount">Rs 1599.00</td>
                        </tr>
                        <tr>
                          <td className="cart_total_label">Total</td>
                          <td className="cart_total_amount">
                            <strong>Rs 1599.00</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section style={{ padding: "50px 0px" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className=" register-form-content">
                <h3 className="page-subheading">3: Select Payment Method</h3>
                <div className style={{ paddingBottom: 30 }}>
                  <div className="order-payment">
                    <div className="payment-method">
                      <div className="form-check ">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios1"
                          defaultValue="option1"
                          defaultChecked
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios1"
                        >
                          {" "}
                          Cash On Deliery
                        </label>
                      </div>
                      <div className="form-check ">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios2"
                          defaultValue="option1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios2"
                        >
                          {" "}
                          Razorpay ( Credit,Debit,UPI,NetBanking)
                        </label>
                      </div>
                      <button type="submit" className="btn-save">
                        <span> Place Order </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="your-order"
                style={{ border: "1px solid #f1f1f1", padding: "10px 10px" }}
              >
                <h3>Your order</h3>
                <div>
                  <div className="your-order-table table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="product-thumbnail">&nbsp;</th>
                          <th className="product-name">Product</th>
                          <th className="product-price">Price</th>
                          <th className="product-quantity">Quantity</th>
                          <th className="product-subtotal">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="product-thumbnail">
                            <img
                              src="/images/sale2.jpg"
                              alt="product1"
                              style={{
                                width: 150,
                                height: 100,
                                objectFit: "contain",
                              }}
                            />
                          </td>
                          <td className="product-name" data-title="Product">
                            {" "}
                            All over floral pattern pattern dress featuring a
                            ruffled tie collar dress-S
                          </td>
                          <td className="product-price" data-title="Price">
                            Rs 1599.00
                          </td>
                          <td
                            className="product-quantity"
                            data-title="Quantity"
                          >
                            <div className="quantity">1</div>
                          </td>
                          <td className="product-subtotal" data-title="Total">
                            Rs 1599.00
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className="table-responsive cart"
                  style={{ background: "rgb(255, 255, 255)" }}
                >
                  <table className="table">
                    <tbody>
                      <tr>
                        <td className="cart_total_label">Cart Subtotal</td>
                        <td className="cart_total_amount">Rs 1599.00</td>
                      </tr>
                      <tr>
                        <td className="cart_total_label">Total</td>
                        <td className="cart_total_amount">
                          <strong>Rs 1599.00</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
